<template>
  <g clip-path="url(#clip0_7307_43293)">
    <rect width="90" height="63.2812" fill="currentColor" />
    <rect x="8.5" y="8.5" width="20" height="6.5" rx="1" fill="white" />
    <rect
      x="8.4375"
      y="31.2891"
      width="31.6406"
      height="2.8125"
      rx="0.703125"
      fill="white"
    />
    <rect
      x="8.4375"
      y="36.9141"
      width="39.0234"
      height="2.8125"
      rx="0.703125"
      fill="white"
    />
    <rect
      x="14.7656"
      y="52.0312"
      width="11.25"
      height="1.40625"
      rx="0.351562"
      fill="white"
    />
    <circle cx="10.5469" cy="52.7344" r="2.10938" fill="#272A40" />
  </g>
  <defs>
    <clipPath id="clip0_7307_43293">
      <rect width="90" height="63.2812" fill="white" />
    </clipPath>
  </defs>
</template>
